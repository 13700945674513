import {FC, ReactNode, useState} from 'react'
import useGenevaFetch from '@/hooks/useGenevaFetch'
import ServerError from '@/views/error/ServerError'
import {ProviderFeedbacksIncidentsInterface} from '@/interfaces/provider_types'
import {
    Box,
    Card,
    CardContent,
    CardHeader,
    Link,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Typography,
} from '@mui/material'
import BallotIcon from '@mui/icons-material/Ballot'
import ReportProblemIcon from '@mui/icons-material/ReportProblem'
import SentimentVeryDissatisfiedIcon from '@mui/icons-material/SentimentVeryDissatisfied'
import SentimentSatisfiedAltIcon from '@mui/icons-material/SentimentSatisfiedAlt'
import ChatIcon from '@mui/icons-material/Chat'
import {formatDate} from '@/utils/formatter'
import GenevaIconAvatar from '@/components/widgets/GenevaIconAvatar'
import {skeletonRows} from '@/utils/table'
import {useRouter} from 'next/router'
import UserAvatar from '@/components/user/UserAvatar'
import useGenevaUser from '@/hooks/useGenevaUser'
import PauseIcon from '@mui/icons-material/Pause'
import dynamic from 'next/dynamic'

const ProviderIncidentsForKeyModal = dynamic(() => import('@/components/provider/ProviderIncidentsForKeyModal'), {
    ssr: false,
})

interface Props {}

const ProviderFeedbackIncidentsCard: FC<Props> = () => {
    const router = useRouter()
    const [isIncidentModalOpen, setIsIncidentModalOpen] = useState(false)
    const [incidentKey, setIncidentKey] = useState('open')
    const {data, isLoading, error} = useGenevaFetch(`/api/reports/feedbacks_incidents`)
    const {genevaApplicationUserId} = useGenevaUser()

    if (error) {
        console.error(error)
        return <ServerError />
    }

    const feedbacks_incidents =
        !isLoading && data ? (data.feedbacks_incidents as ProviderFeedbacksIncidentsInterface) : null

    const getDays = (date: Date) => {
        const dayInMilliseconds = 1000 * 60 * 60 * 24
        if (date) {
            return `(${Math.round((new Date().getTime() - new Date(date).getTime()) / dayInMilliseconds)} days)`
        } else {
            return '-'
        }
    }

    const handleRowClick = (key: string) => {
        setIncidentKey(key)
        if (key === 'feedback') {
            router.push('/provider/feedback')
            return
        }
        setIsIncidentModalOpen(true)
    }

    const headerCellContent = (title: string, description: string, avatar: ReactNode) => {
        return (
            <Box sx={{display: 'flex', alignItems: 'center'}}>
                {avatar}
                <Box sx={{ml: 2, display: 'flex', flexDirection: 'column'}}>
                    <Link variant="subtitle2" underline="hover" sx={{color: 'text.primary', cursor: 'pointer'}}>
                        {title}
                    </Link>
                    <Typography variant="caption" sx={{color: 'text.secondary'}}>
                        {description}
                    </Typography>
                </Box>
            </Box>
        )
    }

    return (
        <Card>
            <CardContent>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell padding={'none'}>
                                <CardHeader
                                    sx={{ml: -2, mt: -2}}
                                    avatar={
                                        <GenevaIconAvatar>
                                            <BallotIcon />
                                        </GenevaIconAvatar>
                                    }
                                    title={'Feedback & Incidents'}
                                />
                            </TableCell>
                            <TableCell padding={'none'} align="right">
                                Open
                            </TableCell>
                            <TableCell padding={'none'} align="right">
                                Oldest
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    {isLoading ? (
                        <TableBody>{skeletonRows(4, 3)}</TableBody>
                    ) : (
                        feedbacks_incidents &&
                        genevaApplicationUserId && (
                            <TableBody>
                                <TableRow hover onClick={() => handleRowClick('user')}>
                                    <TableCell>
                                        {headerCellContent(
                                            'My Incidents',
                                            'Open incidents assigned to me',
                                            <UserAvatar
                                                application_user_id={genevaApplicationUserId}
                                                height={32}
                                                width={32}
                                            />,
                                        )}
                                    </TableCell>
                                    <TableCell align={'right'}>
                                        {feedbacks_incidents.count_of_open_application_user_incidents}
                                    </TableCell>
                                    <TableCell align={'right'}>
                                        {`${formatDate(
                                            feedbacks_incidents.date_of_oldest_application_user_incident,
                                            'dd/MM/yy',
                                        )} ${getDays(feedbacks_incidents.date_of_oldest_application_user_incident)}`}
                                    </TableCell>
                                </TableRow>
                                <TableRow hover onClick={() => handleRowClick('unassigned')}>
                                    <TableCell>
                                        {headerCellContent(
                                            'Unassigned SIRS',
                                            'Count of open unassigned priority 1 or 2 incidents still requiring an incident manager',
                                            <GenevaIconAvatar
                                                color={
                                                    feedbacks_incidents.count_of_unassigned_sirs_incidents > 0
                                                        ? 'error'
                                                        : 'success'
                                                }
                                                height={32}
                                                width={32}
                                            >
                                                <ReportProblemIcon height={32} width={32} />
                                            </GenevaIconAvatar>,
                                        )}
                                    </TableCell>
                                    <TableCell align={'right'}>
                                        {feedbacks_incidents.count_of_unassigned_sirs_incidents}
                                    </TableCell>
                                    <TableCell align={'right'}>
                                        {`${formatDate(
                                            feedbacks_incidents.date_of_oldest_unassigned_sirs_incident,
                                            'dd/MM/yy',
                                        )} ${getDays(feedbacks_incidents.date_of_oldest_unassigned_sirs_incident)}`}
                                    </TableCell>
                                </TableRow>
                                <TableRow hover onClick={() => handleRowClick('unreported')}>
                                    <TableCell>
                                        {headerCellContent(
                                            'Unreported SIRS',
                                            'Count of open SIRS that do not have a reported to SIRS entry',
                                            <GenevaIconAvatar
                                                color={
                                                    feedbacks_incidents.count_of_unreported_sirs_incidents > 0
                                                        ? 'error'
                                                        : 'success'
                                                }
                                                height={32}
                                                width={32}
                                            >
                                                <ReportProblemIcon height={32} width={32} />
                                            </GenevaIconAvatar>,
                                        )}
                                    </TableCell>
                                    <TableCell align={'right'}>
                                        {feedbacks_incidents.count_of_unreported_sirs_incidents}
                                    </TableCell>
                                    <TableCell align={'right'}>
                                        {`${formatDate(
                                            feedbacks_incidents.date_of_oldest_unreported_sirs_incident,
                                            'dd/MM/yy',
                                        )} ${getDays(feedbacks_incidents.date_of_oldest_unreported_sirs_incident)}`}
                                    </TableCell>
                                </TableRow>
                                <TableRow hover onClick={() => handleRowClick('open')}>
                                    <TableCell>
                                        {headerCellContent(
                                            'All Open Incidents',
                                            '',
                                            <GenevaIconAvatar color={'success'} height={32} width={32}>
                                                <BallotIcon height={32} width={32} />
                                            </GenevaIconAvatar>,
                                        )}
                                    </TableCell>
                                    <TableCell align={'right'}>{feedbacks_incidents.count_of_open_incidents}</TableCell>
                                    <TableCell align={'right'}>
                                        {`${formatDate(
                                            feedbacks_incidents.date_of_oldest_incident,
                                            'dd/MM/yy',
                                        )} ${getDays(feedbacks_incidents.date_of_oldest_incident)}`}
                                    </TableCell>
                                </TableRow>
                                <TableRow hover onClick={() => handleRowClick('paused')}>
                                    <TableCell>
                                        {headerCellContent(
                                            'Incidents Awaiting External Response',
                                            '',
                                            <GenevaIconAvatar color={'success'} height={32} width={32}>
                                                <PauseIcon height={32} width={32} />
                                            </GenevaIconAvatar>,
                                        )}
                                    </TableCell>
                                    <TableCell align={'right'}>
                                        {feedbacks_incidents.count_of_paused_incidents}
                                    </TableCell>
                                    <TableCell align={'right'}>
                                        {`${formatDate(
                                            feedbacks_incidents.date_of_oldest_paused_incident,
                                            'dd/MM/yy',
                                        )} ${getDays(feedbacks_incidents.date_of_oldest_paused_incident)}`}
                                    </TableCell>
                                </TableRow>
                                <TableRow hover onClick={() => handleRowClick('feedback')}>
                                    <TableCell>
                                        {headerCellContent(
                                            'Complaints',
                                            '',
                                            <GenevaIconAvatar
                                                color={
                                                    feedbacks_incidents.count_of_open_complaints > 0
                                                        ? 'warning'
                                                        : 'success'
                                                }
                                                height={32}
                                                width={32}
                                            >
                                                <SentimentVeryDissatisfiedIcon height={32} width={32} />
                                            </GenevaIconAvatar>,
                                        )}
                                    </TableCell>
                                    <TableCell align={'right'}>
                                        {feedbacks_incidents.count_of_open_complaints}
                                    </TableCell>
                                    <TableCell align={'right'}>
                                        {`${formatDate(
                                            feedbacks_incidents.date_of_oldest_complaint,
                                            'dd/MM/yy',
                                        )} ${getDays(feedbacks_incidents.date_of_oldest_complaint)}`}
                                    </TableCell>
                                </TableRow>
                                <TableRow hover onClick={() => handleRowClick('feedback')}>
                                    <TableCell>
                                        {headerCellContent(
                                            'Complaints Awaiting External Response',
                                            '',
                                            <GenevaIconAvatar
                                                color={
                                                    feedbacks_incidents.count_of_paused_complaints > 0
                                                        ? 'warning'
                                                        : 'success'
                                                }
                                                height={32}
                                                width={32}
                                            >
                                                <PauseIcon height={32} width={32} />
                                            </GenevaIconAvatar>,
                                        )}
                                    </TableCell>
                                    <TableCell align={'right'}>
                                        {feedbacks_incidents.count_of_paused_complaints}
                                    </TableCell>
                                    <TableCell align={'right'}>
                                        {`${formatDate(
                                            feedbacks_incidents.date_of_oldest_complaint,
                                            'dd/MM/yy',
                                        )} ${getDays(feedbacks_incidents.date_of_oldest_complaint)}`}
                                    </TableCell>
                                </TableRow>
                                <TableRow hover onClick={() => handleRowClick('feedback')}>
                                    <TableCell>
                                        {headerCellContent(
                                            'Suggestions',
                                            '',
                                            <GenevaIconAvatar color={'success'} height={32} width={32}>
                                                <ChatIcon height={32} width={32} />
                                            </GenevaIconAvatar>,
                                        )}
                                    </TableCell>
                                    <TableCell align={'right'}>
                                        {feedbacks_incidents.count_of_open_suggestions}
                                    </TableCell>
                                    <TableCell align={'right'}>
                                        {`${formatDate(
                                            feedbacks_incidents.date_of_oldest_suggestion,
                                            'dd/MM/yy',
                                        )} ${getDays(feedbacks_incidents.date_of_oldest_suggestion)}`}
                                    </TableCell>
                                </TableRow>
                                <TableRow hover onClick={() => handleRowClick('feedback')}>
                                    <TableCell>
                                        {headerCellContent(
                                            'Compliments',
                                            '',
                                            <GenevaIconAvatar color={'success'} height={32} width={32}>
                                                <SentimentSatisfiedAltIcon height={32} width={32} />
                                            </GenevaIconAvatar>,
                                        )}
                                    </TableCell>
                                    <TableCell align={'right'}>
                                        {feedbacks_incidents.count_of_open_compliments}
                                    </TableCell>
                                    <TableCell align={'right'}>
                                        {`${formatDate(
                                            feedbacks_incidents.date_of_oldest_compliment,
                                            'dd/MM/yy',
                                        )} ${getDays(feedbacks_incidents.date_of_oldest_compliment)}`}
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        )
                    )}
                </Table>
            </CardContent>
            {isIncidentModalOpen && incidentKey && (
                <ProviderIncidentsForKeyModal
                    incidentKey={incidentKey}
                    onClose={() => setIsIncidentModalOpen(false)}
                    open={isIncidentModalOpen}
                />
            )}
        </Card>
    )
}

export default ProviderFeedbackIncidentsCard
