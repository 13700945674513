import {FC} from 'react'
import {GenevaUserInterface} from '@/interfaces/user_types'
import useSettings from '@/hooks/useSettings'
import LoadingScreen from '@/components/widgets/LoadingScreen'
import Page from '@/components/Page'
import {Box, Container} from '@mui/material'
import Header from '@/views/user/UserHomePage/Header'
import Overview from '@/views/user/UserHomePage/Overview'

interface UserHomePageProps {
    application_user: GenevaUserInterface
}

const UserHomePage: FC<UserHomePageProps> = ({application_user}) => {
    const {settings} = useSettings()

    if (!application_user) {
        return <LoadingScreen />
    }

    return (
        <Page title="User Dashboard">
            <Container maxWidth={settings.compact ? 'xl' : false}>
                <Header />
                <Box sx={{mt: 3}}>
                    <Overview application_user={application_user} />
                </Box>
            </Container>
        </Page>
    )
}

export default UserHomePage
