import {FC, ReactNode} from 'react'
import DashboardLayout from '@/layouts/ProviderDashboardLayout/DashboardLayout'
import {homePageSections} from '@/config/homePageNavbarSections'

interface OwnProps {
    children?: ReactNode
}

type Props = OwnProps

const HomePageDashboard: FC<Props> = (props) => {
    return <DashboardLayout sections={homePageSections()}>{props.children}</DashboardLayout>
}

export default HomePageDashboard
