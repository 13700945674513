import {FC, useState} from 'react'
import {Alert, Box, Typography} from '@mui/material'
import useGenevaUser from '@/hooks/useGenevaUser'
import UserAvatar from '@/components/user/UserAvatar'
import UserIncidentAlert from '@/components/user/UserIncidentAlert'
import useGenevaFetch from '@/hooks/useGenevaFetch'
import {OverdueCarePlanActivationsInterface} from '@/interfaces/user_types'
import ServerError from '@/views/error/ServerError'
import dynamic from 'next/dynamic'

const OverdueCarePlanActivationsClientModal = dynamic(
    () => import('@/components/widgets/Layout/OverdueCarePlanActivationsClientModal'),
    {ssr: false},
)

interface Props {}

const UserPageHeading: FC<Props> = () => {
    const {genevaUser, isGenevaLoading} = useGenevaUser()
    const [isOverdueClientsModalOpen, setIsOverdueClientsModalOpen] = useState<boolean>(false)

    const {data, isLoading, error} = useGenevaFetch('/api/users/overdue_care_plan_activations')

    const isUserIncidentManager = !isGenevaLoading && genevaUser && genevaUser.is_incident_manager

    if (error) {
        return <ServerError />
    }

    const overdue =
        !isLoading && data ? (data.overdue_care_plan_activations as OverdueCarePlanActivationsInterface) : null

    return (
        <>
            <Box sx={{display: 'flex', pb: 2, alignItems: 'center'}}>
                <Box sx={{display: 'flex', alignItems: 'center'}}>
                    <Box sx={{mr: 2}}>{genevaUser && <UserAvatar application_user_id={genevaUser.id} />}</Box>
                    <Typography variant="h5" data-cy="userFullName" sx={{color: 'text.primary'}}>
                        {genevaUser && genevaUser.description}'s Dashboard
                    </Typography>
                </Box>
                {overdue && overdue.overdue_care_plan_activation_clients.length > 0 && (
                    <Box sx={{ml: 2}}>
                        <Alert
                            severity="error"
                            sx={{cursor: 'pointer'}}
                            onClick={() => setIsOverdueClientsModalOpen(true)}
                        >
                            {`${overdue.overdue_care_plan_activation_clients.length} new client${
                                overdue.overdue_care_plan_activation_clients.length > 1 ? 's' : ''
                            } with overdue care plan activation`}
                        </Alert>
                    </Box>
                )}
            </Box>
            {isUserIncidentManager && <UserIncidentAlert />}
            {isOverdueClientsModalOpen && overdue && genevaUser && (
                <OverdueCarePlanActivationsClientModal
                    overdueCarePlanActivations={overdue}
                    isManager={genevaUser.is_manager || genevaUser.is_admin}
                    onClose={() => setIsOverdueClientsModalOpen(false)}
                    open={isOverdueClientsModalOpen}
                />
            )}
        </>
    )
}

export default UserPageHeading
