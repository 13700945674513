import {FC} from 'react'
import GenevaNextLink from '@/components/widgets/Layout/GenevaNextLink'
import {useRouter} from 'next/router'
import {
    Box,
    Card,
    CardActionArea,
    CardHeader,
    Divider,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
} from '@mui/material'
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn'
import AccessibilityNewIcon from '@mui/icons-material/AccessibilityNew'
import DateRangeIcon from '@mui/icons-material/DateRange'
import {skeletonRows} from '@/utils/table'
import {CalendarEventEnum, CalendarEventInterface} from '@/interfaces/appointment_types'
import {formatDate, formatTime} from '@/utils/formatter'
import GenevaIconAvatar from '@/components/widgets/GenevaIconAvatar'

interface Props {
    isLoading: boolean
    appointments: CalendarEventInterface[]
}

const WorkerUpcomingAppointmentsList: FC<Props> = ({isLoading, appointments}) => {
    const router = useRouter()

    const handleGoToSchedule = () => {
        router.push(`/my_clients/my_schedule`)
    }

    return (
        <Card>
            <CardActionArea onClick={handleGoToSchedule}>
                <CardHeader
                    avatar={
                        <GenevaIconAvatar>
                            <DateRangeIcon />
                        </GenevaIconAvatar>
                    }
                    title={'Upcoming Appointments'}
                />
            </CardActionArea>
            <Divider />
            <Box>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Date</TableCell>
                            <TableCell>Time</TableCell>
                            <TableCell>Event</TableCell>
                            <TableCell>Client</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {isLoading
                            ? skeletonRows(5, 4)
                            : appointments.map((appointment) => {
                                  const apptHref =
                                      appointment.eventType === CalendarEventEnum.MEETING_APPOINTMENT
                                          ? `/client/${appointment.resource.client_id}/schedule/meeting/${appointment.resource.id}`
                                          : `/client/${appointment.resource.client_id}/schedule/${appointment.resource.id}`
                                  return (
                                      <GenevaNextLink
                                          key={`${appointment.resource.id}`}
                                          href={apptHref}
                                          passHref
                                          legacyBehavior
                                      >
                                          <TableRow hover key={appointment.resource.id}>
                                              <TableCell>
                                                  {appointment.eventType === CalendarEventEnum.MEETING_APPOINTMENT ? (
                                                      <AssignmentTurnedInIcon fontSize="small" color="primary" />
                                                  ) : (
                                                      <AccessibilityNewIcon fontSize="small" color="action" />
                                                  )}
                                                  &nbsp;
                                                  {formatDate(appointment.start)}
                                              </TableCell>
                                              <TableCell>
                                                  {`${formatTime(
                                                      new Date(appointment.start),
                                                  )} - ${formatTime(new Date(appointment.end))}`}
                                              </TableCell>
                                              <TableCell>{appointment.title_description}</TableCell>
                                              <TableCell>{appointment.client_description}</TableCell>
                                          </TableRow>
                                      </GenevaNextLink>
                                  )
                              })}
                    </TableBody>
                </Table>
            </Box>
        </Card>
    )
}

export default WorkerUpcomingAppointmentsList
