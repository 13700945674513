import {FC, SyntheticEvent, useEffect, useState} from 'react'
import {Box, Card, CardContent, CardHeader, Tab, Tabs} from '@mui/material'
import {DataGridPro, GridColDef} from '@mui/x-data-grid-pro'
import {formatDate} from '@/utils/formatter'
import useGenevaFetch from '@/hooks/useGenevaFetch'
import ServerError from '@/views/error/ServerError'
import {ProviderApplicationUserLeaveReportLineInterface} from '@/interfaces/provider_types'
import GenevaIconAvatar from '@/components/widgets/GenevaIconAvatar'
import DomainAddIcon from '@mui/icons-material/DomainAdd'

interface Props {}

const ProviderLoggedLeaveCard: FC<Props> = ({}) => {
    const [selectedTab, setSelectedTab] = useState<string>('current')
    const [localLeavePeriods, setLocalLeavePeriods] = useState<ProviderApplicationUserLeaveReportLineInterface[]>([])

    const {data, isLoading, error} = useGenevaFetch(
        `/api/provider/application_user_leave_report?report_type=${selectedTab}`,
    )

    useEffect(() => {
        if (!isLoading && data) {
            setLocalLeavePeriods(data.report.lines)
        }
    }, [isLoading, data])

    if (error) {
        return <ServerError />
    }

    const tabs = [
        {value: 'current', label: 'Current'},
        {value: 'upcoming', label: 'Upcoming'},
    ]

    const handleTabsChange = async (_event: SyntheticEvent<{}>, value: string) => {
        setSelectedTab(value)
    }

    const columns: GridColDef[] = [
        {
            field: 'application_user_description',
            headerName: 'Member',
            flex: 0.5,
            align: 'left',
            renderCell: (cellValues) => {
                return cellValues.value
            },
        },
        {
            field: 'application_user_role_description',
            headerName: 'Position',
            flex: 0.5,
            align: 'left',
            renderCell: (cellValues) => {
                return cellValues.value
            },
        },
        {
            field: 'application_user_reports_to_description',
            headerName: 'Reports To',
            flex: 0.5,
            align: 'left',
            renderCell: (cellValues) => {
                return cellValues.value
            },
        },
        {
            field: 'leave_from_date',
            headerName: 'Leave From',
            flex: 0.5,
            align: 'left',
            renderCell: (cellValues) => {
                return formatDate(cellValues.value)
            },
        },
        {
            field: 'leave_to_date',
            headerName: 'Leave To',
            flex: 0.5,
            align: 'left',
            renderCell: (cellValues) => {
                return formatDate(cellValues.value)
            },
        },
    ]

    return (
        <Card>
            <CardContent>
                <Box sx={{display: 'flex', justifyContent: 'space-between'}}>
                    <CardHeader
                        sx={{p: 0}}
                        avatar={
                            <GenevaIconAvatar>
                                <DomainAddIcon />
                            </GenevaIconAvatar>
                        }
                        title={'Logged Staff Leave'}
                    />
                    <Tabs
                        indicatorColor="primary"
                        onChange={handleTabsChange}
                        scrollButtons="auto"
                        textColor="primary"
                        value={selectedTab}
                        variant="scrollable"
                    >
                        {tabs.map((tab) => (
                            <Tab key={tab.value} label={tab.label} value={tab.value} />
                        ))}
                    </Tabs>
                </Box>
                <DataGridPro
                    sx={{border: '0px'}}
                    columns={columns}
                    rows={localLeavePeriods}
                    loading={isLoading}
                    pageSizeOptions={[5, 10, 20]}
                    pagination
                    initialState={{
                        pagination: {
                            paginationModel: {
                                pageSize: 10,
                            },
                        },
                    }}
                />
            </CardContent>
        </Card>
    )
}
export default ProviderLoggedLeaveCard
