import {TaskInterface} from '@/interfaces/task_types'
import {HtmlTooltip} from '@/components/widgets/HTMLTooltip'
import {Typography} from '@mui/material'

export function getSimpleTaskDescription(task: TaskInterface) {
    if (task.is_client_task) {
        return task.person_full_name
    } else {
        return task.task_object_description
    }
}

export function getTaskSubjectTypography(task: TaskInterface) {
    if (task.description) {
        return (
            <HtmlTooltip title={task.description}>
                <Typography variant="body2">{task.subject}</Typography>
            </HtmlTooltip>
        )
    } else {
        return <Typography variant="subtitle2">{task.subject}</Typography>
    }
}

export function getUrlForTask(task: TaskInterface) {
    if (task.is_client_task) {
        return `/client/${task.person_id}/tasks/${task.id}?task_type=client`
    }
    if (task.is_supplier_task) {
        return `/supplier/${task.supplier_id}/tasks/${task.id}?task_type=supplier`
    }
    if (task.is_lead_task) {
        return `/sales/lead/${task.lead_id}/tasks/${task.id}?task_type=lead`
    }
    if (task.is_supplier_lead_task) {
        return `/supplier_lead/${task.supplier_lead_id}/tasks/${task.id}`
    }
    if (task.is_finance_task) {
        return ''
    }
    return ''
}

export function getKeyForTask(task: TaskInterface, title: string) {
    if (task.is_client_task) {
        return `NEXT_LINK_${title?.split(' ').join('_').toUpperCase()}_CLIENT_${task.id}`
    }
    if (task.is_supplier_task) {
        return `NEXT_LINK_${title?.split(' ').join('_').toUpperCase()}_SUPPLIER_${task.id}`
    }
    if (task.is_lead_task) {
        return `NEXT_LINK_${title?.split(' ').join('_').toUpperCase()}_LEAD_${task.id}`
    }
    if (task.is_supplier_lead_task) {
        return `NEXT_LINK_${title?.split(' ').join('_').toUpperCase()}_SUPPLIER_LEAD_${task.id}`
    }
    return ''
}
