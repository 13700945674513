import {FC, ReactNode} from 'react'
import GenevaNextLink from '@/components/widgets/Layout/GenevaNextLink'
import {Box, SxProps} from '@mui/material'
import {Theme} from '@mui/system'

interface LinkWrapperProps {
    link: string
    key?: string
    children: ReactNode
    openInNewTab?: boolean
    extraSx?: SxProps<Theme>
}

const LinkWrapper: FC<LinkWrapperProps> = ({link, key, children, openInNewTab = false, extraSx = {}}) => {
    return (
        <GenevaNextLink key={link} href={key ? key : link} passHref openInNewTab={openInNewTab}>
            <Box sx={{cursor: 'pointer', ...extraSx}}>{children}</Box>
        </GenevaNextLink>
    )
}

export default LinkWrapper
