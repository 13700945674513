import {FC, useState} from 'react'
import {Box, Button, Grid2 as Grid} from '@mui/material'
import AddIcon from '@mui/icons-material/Add'
import {useRouter} from 'next/router'
import {SupplierInterface, SupplierLeadInterface} from '@/interfaces/supplier_types'
import useGenevaUser from '@/hooks/useGenevaUser'
import {LeadInterface} from '@/interfaces/lead_types'
import UserPageHeading from '@/components/widgets/Layout/UserPageHeading'
import dynamic from 'next/dynamic'

const LeadCreateModal = dynamic(() => import('@/components/sales/lead/LeadCreateModal'), {ssr: false})
const SupplierCreateModal = dynamic(() => import('@/components/supplier/SupplierCreateModal'), {ssr: false})
const SupplierLeadCreateModal = dynamic(
    () => import('@/components/service/RecurrenceRuleCardEdit/SupplierLeadCreateModal'),
    {ssr: false},
)

interface HeaderProps {}

const Header: FC<HeaderProps> = () => {
    const router = useRouter()
    const {genevaUser, isGenevaLoading} = useGenevaUser()
    const [isLeadModalOpen, setIsLeadModalOpen] = useState<boolean>(false)
    const [isSupplierModalOpen, setIsSupplierModalOpen] = useState<boolean>(false)
    const [isSupplierLeadModalOpen, setIsSupplierLeadModalOpen] = useState<boolean>(false)

    const onLeadComplete = async (lead: LeadInterface) => {
        setIsLeadModalOpen(false)
        await router.push(`/sales/lead/${lead.id}`)
    }

    const onSupplierComplete = async (newSupplier: SupplierInterface) => {
        setIsSupplierModalOpen(false)
        await router.push(`/supplier/${newSupplier.id}`)
    }

    const onSupplierLeadComplete = async (newSupplierLead: SupplierLeadInterface) => {
        setIsSupplierLeadModalOpen(false)
        await router.push(`/supplier_lead/${newSupplierLead.id}`)
    }

    const canCreateSupplier = !isGenevaLoading && genevaUser ? genevaUser.can_create_supplier : false

    return (
        <>
            <Grid container spacing={3} sx={{justifyContent: 'space-between'}}>
                <Grid>
                    <UserPageHeading />
                </Grid>
                <Grid>
                    <Box sx={{display: 'flex', flexDirection: 'row'}}>
                        <Box>
                            <Button
                                color="primary"
                                startIcon={<AddIcon fontSize="small" />}
                                sx={{m: 1}}
                                variant="contained"
                                onClick={() => setIsLeadModalOpen(true)}
                            >
                                New Lead
                            </Button>
                        </Box>
                        <Box>
                            <Button
                                color="primary"
                                startIcon={<AddIcon fontSize="small" />}
                                sx={{m: 1}}
                                variant="contained"
                                disabled={!canCreateSupplier}
                                onClick={() => setIsSupplierModalOpen(true)}
                            >
                                New Supplier
                            </Button>
                        </Box>
                        <Box>
                            <Button
                                color="primary"
                                startIcon={<AddIcon fontSize="small" />}
                                sx={{m: 1}}
                                variant="contained"
                                onClick={() => setIsSupplierLeadModalOpen(true)}
                            >
                                New Supplier Lead
                            </Button>
                        </Box>
                        {isLeadModalOpen && (
                            <LeadCreateModal
                                onClose={() => setIsLeadModalOpen(false)}
                                onComplete={onLeadComplete}
                                open={isLeadModalOpen}
                            />
                        )}
                        {isSupplierModalOpen && (
                            <SupplierCreateModal
                                onClose={() => setIsSupplierModalOpen(false)}
                                onComplete={onSupplierComplete}
                                open={isSupplierModalOpen}
                            />
                        )}
                        {isSupplierLeadModalOpen && (
                            <SupplierLeadCreateModal
                                onClose={() => setIsSupplierLeadModalOpen(false)}
                                onComplete={onSupplierLeadComplete}
                                open={isSupplierLeadModalOpen}
                            />
                        )}
                    </Box>
                </Grid>
            </Grid>
        </>
    )
}

export default Header
