import {AuditEntityInterface, HistoryEntityInterface} from '@/interfaces/entity_types'
import {PersonInterface} from '@/interfaces/person_types'
import {ContractLeaveInterface} from '@/interfaces/contract_types'
import {RecurrenceRuleBookingRequestPauseInterface, RecurrenceRuleInterface} from '@/interfaces/contract_service_types'
import {AppointmentCancellationSourceInterface, LookupInterface} from '@/interfaces/lookup_types'
import {SupplierCancellationPolicyInterface, SupplierInterface} from '@/interfaces/supplier_types'
import {
    AppointmentAttendanceLocationEnum,
    AppointmentChoiceSummaryInterface,
    AppointmentCostsInterface,
    PotentialAppointmentChoicesInterface,
    SupplierServiceInterface,
} from '@/interfaces/service_types'
import {PricingConfigurationInterface} from '@/interfaces/pricing_types'
import {AssessmentInterface} from '@/interfaces/assessment_types'
import {BookingGroupUrgencyEnum} from '@/interfaces/bookings_types'

export interface CalendarInterface extends HistoryEntityInterface {
    client_id: number
    worker_id: number | null
    supplier_id: number
    address_id: number
    service_id: number
    scheduled_start_date: Date
    scheduled_end_date: Date
    actual_start_date: Date
    actual_end_date: Date

    scheduled_start_date_tz: Date
    scheduled_end_date_tz: Date
    actual_start_date_tz: Date
    actual_end_date_tz: Date

    is_appointment: boolean
    is_draft_appointment: boolean
    is_meeting_appointment: boolean
    is_contract_leave: boolean

    title: string
    description: string
    start_to_end_description: string
    address_description: string
    suburb_description: string
    supplier_description: string
    client_description: string
    client_person_application_user_id: number
    worker_full_name: string
    client_first_last_name: string
    client_full_name: string
    has_open_tasks: boolean

    lu_leave_type_description: string
    leave_start_date: Date
    leave_end_date: Date
    follow_up_date: Date
}

export interface AppointmentNoticeInterface extends HistoryEntityInterface {
    id: number
    appointment_id: number
    details: string
}

export interface AppointmentInterface extends CalendarInterface {
    [key: string]: any

    contract_service_id: number
    ledger_header_id: number
    reversal_ledger_header_id: number
    recurrence_rule_id: number
    provider_description: string
    supplier_payable_ledger_header_id: number
    supplier_payment_ledger_header_id: number
    appointment_type_service_item_id: number | null

    search_terms: string
    client_cost_ex_gst: number
    client_cost_gst: number
    client_cost_inc_gst: number
    is_override_amount_to_client: boolean
    supplier_base_cost_ex_gst: number
    supplier_base_cost_gst: number
    supplier_base_cost_inc_gst: number
    supplier_travel_cost_ex_gst: number
    supplier_kilometers_travelled: number
    supplier_travel_cost_gst: number
    supplier_travel_cost_inc_gst: number
    supplier_call_out_fee_ex_gst: number
    supplier_call_out_fee_gst: number
    supplier_call_out_fee_inc_gst: number
    supplier_min_time_charge_ex_gst: number
    supplier_min_time_charge_gst: number
    supplier_min_time_charge_inc_gst: number
    is_override_supplier_min_time_charge: boolean
    supplier_admin_fee_ex_gst: number
    supplier_admin_fee_gst: number
    supplier_admin_fee_inc_gst: number
    supplier_cost_ex_gst: number
    supplier_cost_gst: number
    supplier_cost_inc_gst: number
    is_override_amount_to_supplier: boolean
    is_incurs_cancellation_fee: boolean
    is_cancellation_fee_charged_to_provider: boolean
    total_surcharges_ex_gst: number
    total_surcharges_gst: number
    total_surcharges_inc_gst: number

    final_start_date: Date
    final_end_date: Date
    final_duration_minutes: number

    supplier_rebate_ex_gst: number
    supplier_rebate_gst: number
    supplier_rebate_inc_gst: number

    margin: number
    lu_appointment_status_id: number
    description: string
    title: string
    status: string
    lu_appointment_payment_status_id: number
    payment_status: string
    supplier_portal_status: string
    series_description: string
    is_ad_hoc: boolean
    is_proposed: boolean
    is_booking_requested: boolean
    is_booked: boolean
    is_in_progress: boolean
    is_completed: boolean
    is_paid: boolean
    is_cancellation_requested: boolean
    is_cancelled: boolean
    is_disputed: boolean
    is_reschedule_requested: boolean
    is_approval_required: boolean
    is_conflicted: boolean
    scheduled_duration_minutes: number
    actual_duration_minutes: number

    can_be_set_to_proposed: boolean
    can_be_set_to_booking_requested: boolean
    can_be_rescheduled: boolean
    can_be_set_to_reschedule_requested: boolean
    can_be_set_to_cancelled: boolean
    can_be_set_to_cancellation_requested: boolean
    can_be_set_to_disputed: boolean
    can_be_set_to_approval_required: boolean
    can_be_set_to_conflicted: boolean
    can_be_edited: boolean
    can_create_adjustment: boolean

    service_description: string
    is_exclude_client_preferences: boolean
    lu_home_care_service_category_description: string

    is_detached_from_recurrence_rule: boolean
    work_instruction_id: number
    is_additional_time_charge: boolean

    client_description: string
    supplier_description: string
    client_person_application_user_id: number
    client_gender_description: string
    client_age: number

    check_in_geotag_event_id: number
    check_out_geotag_event_id: number

    appointment_cancellation_source_description: string
    appointment_cancellation_client_reason_description: string
    appointment_cancellation_provider_reason_description: string
    appointment_cancellation_worker_reason_description: string
    full_appointment_cancellation_description: string

    cancelled_by_application_user_description: string
    service_coordinator_application_user_description: string
    care_manager_application_user_description: string

    draft_appointment: AppointmentInterface
    appointment_notices: AppointmentNoticeInterface[]
    start_to_end_time_description: string
    first_point_of_contact_details: string
    is_client_first_point_of_contact: boolean
    is_supplier_contact_permitted: boolean
    first_point_of_contact_description: string
    first_point_of_contact_relationship_description: string
    first_point_of_contact_person_mobile: string
    first_point_of_contact_person_home_phone: string

    is_historical: string
    urgency: BookingGroupUrgencyEnum
    engaged_supplier_description: string
    is_booking_paused: boolean
    is_future_dated: boolean

    booked_on: Date
    booked_on_age_in_days: number
    booked_by_application_user_description: string

    supplier_cost_inc_gst_adjustment: number
    surcharge_cost_inc_gst_adjustment: number
    supplier_appointment_cost_inc_gst_adjustment: number
    client_cost_inc_gst_adjustment: number
    has_adjustment_post_completion: boolean

    original_scheduled_start_date: Date
    original_scheduled_end_date: Date
    booked_scheduled_start_date: Date
    booked_scheduled_end_date: Date
    original_scheduled_duration_minutes: number
    booked_scheduled_duration_minutes: number
    original_to_booked_start_date_delta_minutes: number
    original_to_booked_end_date_delta_minutes: number
    original_to_booked_duration_delta_minutes: number
    booked_to_final_start_date_delta_minutes: number
    booked_to_final_end_date_delta_minutes: number
    booked_to_final_duration_delta_minutes: number

    has_booked_to_final_start_date_adjustment: boolean
    has_booked_to_final_end_date_adjustment: boolean
    has_time_adjustment: boolean
    has_original_to_booked_time_adjustment: boolean
    has_booked_to_final_time_adjustment: boolean

    is_booked_on_supplier_portal: boolean
    has_booked_to_final_date_adjustment_only: boolean
    has_original_to_booked_date_adjustment_only: boolean
    has_updated_booked_to_final_duration_and_date: boolean
    has_updated_original_to_booked_duration_and_date: boolean
    is_booked_after_original_scheduled_start_date: boolean

    active_recurrence_rule_booking_request_pause: RecurrenceRuleBookingRequestPauseInterface
    is_calculator: boolean
}

export interface AppointmentEditInterface extends AppointmentInterface {
    has_no_pricing: boolean
    has_no_pricing_reasons: string[]
    costs_additional_info: string[]
    has_default_pricing: boolean

    is_requires_override: boolean
    is_requires_override_reasons: string[]
    appointment_type_service_item_supplier_description: string

    pricing_configuration: PricingConfigurationInterface
    appointment_costs: AppointmentCostsInterface
}

export const enum MeetingAppointmentTypeIdEnum {
    IN_PERSON_MEETING = 1,
    PHONE_MEETING = 2,
    CHECK_IN_CALL = 3,
}

export const enum MeetingAppointmentSubTypeIdEnum {
    PHONE_CALL = 300,
    VIDEO_CALL = 310,
}

export interface MeetingAppointmentInterface extends AppointmentInterface {
    details: string

    lead_id: number
    representative_person_id: number
    check_in_recurrence_rule_id: number

    lu_meeting_type_id: number
    lu_meeting_sub_type_id: number
    meeting_type_description: string
    meeting_sub_type_description: string
    lu_meeting_appointment_cancellation_reason_id: number
    lu_initial_care_manager_assessment_outcome_id: number
    is_phone: boolean
    is_in_person: boolean
    is_address_required: boolean
    is_translator_required: boolean
    is_representative_present: boolean
    is_confirmed_with_client: boolean
    is_check_in_call: boolean
    is_initial_care_manager_assessment: boolean
    is_in_home_assessment_care_planning: boolean
    other_cancellation_reason: string

    meeting_appointment_cancellation_reason_description: string

    representative_person_description: string

    meeting_appointment_meeting_appointment_types: MeetingApptMeetingApptTypeInterface[]
    possible_meeting_appointment_types: MeetingApptMeetingApptTypeInterface[]
    all_meeting_appointment_types: MeetingApptMeetingApptTypeInterface[]
    meeting_appointment_persons: MeetingAppointmentPersonInterface[]
    possible_meeting_appointment_persons: MeetingAppointmentPersonInterface[]
    all_meeting_appointment_persons: MeetingAppointmentPersonInterface[]
    assessment: AssessmentInterface

    can_be_set_to_completed: boolean
    series_description: string
}

export interface MeetingApptMeetingApptTypeInterface extends HistoryEntityInterface {
    meeting_appointment_id: number
    lu_meeting_appointment_type_id: number
    is_active: boolean

    meeting_appointment: MeetingAppointmentInterface
    meeting_appointment_type_description: string
}

export interface MeetingAppointmentPersonInterface extends HistoryEntityInterface {
    meeting_appointment_id: number
    person_id: number
    is_active: boolean

    person_first_last_name: string
}

export interface SeriesAppointmentInterface extends AppointmentInterface {
    keep: boolean
    cancel: boolean
    reschedule: boolean
}

export const enum LU_APPT_CANCELLATION_SOURCE {
    LU_APPT_CANCELLATION_SOURCE_PROVIDER = 1,
    LU_APPT_CANCELLATION_SOURCE_WORKER = 2,
    LU_APPT_CANCELLATION_SOURCE_CLIENT = 3,
}

export interface AppointmentCancellationInterface {
    lu_appointment_cancellation_source_id: LU_APPT_CANCELLATION_SOURCE | null
    lu_appointment_cancellation_reason_id: number | null
    other_reason: string | null
    is_incurs_cancellation_fee: boolean | null
    is_cancellation_fee_charged_to_provider: boolean | null
    cancellation_fee_comment: string | null
}

export interface ReallocateAppointmentInterface extends AppointmentInterface {
    reallocate: boolean
}

export interface LeaveAppointmentsInterface {
    leaveAppointments: SeriesAppointmentInterface[]
    careManagerReview: boolean
    careManageComment: string
}

export interface OffboardingCancelAppointmentInterface extends AppointmentInterface {
    keep: boolean
    cancel: boolean
}

export const enum CalendarEventEnum {
    APPOINTMENT,
    MEETING_APPOINTMENT,
    LEAVE,
    ACAT_ASSESSMENT,
}

export interface CalendarResourceInterface {
    id: number
    lu_appointment_status_id: number

    client_cost_inc_gst: number

    client_id: number

    is_appointment: boolean
    is_draft_appointment: boolean
    is_meeting_appointment: boolean
    is_contract_leave: boolean
    suspend_package: boolean
}

export interface CalendarEventInterface {
    [key: string]: string | Date | number | CalendarInterface | boolean | CalendarResourceInterface

    title: string
    start: Date
    end: Date
    allDay: Date

    title_description: string
    supplier_description: string
    supplier_person_description: string
    client_description: string
    address_description: string
    suburb_description: string
    client_person_application_user_id: number

    eventType: CalendarEventEnum
    resource: CalendarResourceInterface

    lu_appointment_status_id: number
    status: string
    is_series_paused: boolean
    is_confirmed_with_client: boolean
}

export interface ScheduleInterface {
    person: PersonInterface
    is_client: boolean
    is_worker: boolean
    is_on_contract_leave: boolean
    current_contract_leave: ContractLeaveInterface

    upcoming_appointment_events: CalendarEventInterface[]
    today_appointment_events: CalendarEventInterface[]
    events: CalendarEventInterface[]
}

export const enum AppointmentStatusEnum {
    PROPOSED = 1,
    BOOKING_REQUESTED = 2,
    BOOKED = 3,
    IN_PROGRESS = 4,
    COMPLETED = 5,
    PAYABLE_GENERATED = 6,
    CANCELLATION_REQUESTED = 7,
    CANCELLED = 8,
    CONFLICTED = 9,
    DISPUTED = 10,
    PAYMENT_MADE = 11,
    RESCHEDULE_REQUESTED = 12,
    APPROVAL_REQUIRED = 13,
}

export const enum AppointmentPaymentStatusEnum {
    PAYABLE_GENERATED = 1,
    PAYMENT_MADE = 2,
}

export interface AppointmentCancellationControllerInterface {
    appointment: AppointmentInterface

    lu_appointment_cancellation_source_id: number
    lu_appointment_cancellation_reason_id: number | null

    other_reason: string

    is_incurs_cancellation_fee: boolean
    is_cancellation_fee_charged_to_provider: boolean

    appointment_cancellation_sources: AppointmentCancellationSourceInterface[]
    supplier_cancellation_policy: SupplierCancellationPolicyInterface | null
    is_subject_to_cancellation_fee: boolean
    cancellation_costs: AppointmentCostsInterface
    cancellation_fee_comment: string | null
}

export interface AppointmentsSetToProposedControllerInterface {
    appointments: AppointmentEditInterface[]
    recurrence_rule?: RecurrenceRuleInterface

    description: string

    lu_appointment_cancellation_source_id: number | null
    lu_appointment_cancellation_reason_id: number | null

    other_reason: string | null

    appointment_cancellation_sources: AppointmentCancellationSourceInterface[]
    has_open_supplier_booking_requests: boolean

    is_close_all_supplier_booking_requests: boolean
}

export interface AppointmentsSetToCancelledControllerInterface {
    appointments: AppointmentEditInterface[]
    recurrence_rule?: RecurrenceRuleInterface

    description: string

    lu_appointment_cancellation_source_id: number | null
    lu_appointment_cancellation_reason_id: number | null

    other_reason: string | null

    appointment_cancellation_sources: AppointmentCancellationSourceInterface[]
    has_open_supplier_booking_requests: boolean

    is_close_all_supplier_booking_requests: boolean
}

export interface PotentialAttendanceLocationInterface {
    lu_appointment_attendance_location_id: number
    description: string
    appointments: AppointmentEditInterface[]
    has_valid_appointments: boolean
    appointment_choice_summaries: AppointmentChoiceSummaryInterface[]
    potential_appointment_choices: PotentialAppointmentChoicesInterface[]
}

export interface AppointmentsSetToBookedControllerInterface {
    appointments: AppointmentEditInterface[]
    recurrence_rule: RecurrenceRuleInterface
    description: string
    supplier_booking_request_id: number
    request_identifier: string

    available_supplier_services: SupplierServiceInterface[]
    suppliers: SupplierInterface[]

    client_id: number
    address_id: number
    service_id: number
    supplier_id: number
    override_engaged_supplier_id: number | null
    worker_id: number | null
    lu_appointment_attendance_location_id: AppointmentAttendanceLocationEnum
    lu_appointment_attendance_location_description: string
    service_description: string
    address_description: string
    street_address: string
    suburb_state_postcode: string
    supplier_description: string
    frequency_description: string
    client_description: string
    client_gender_description: string
    client_age: number
    workers: PersonInterface[]

    first_appointment_date: Date
    proposed_start_date: Date | string
    proposed_end_date: Date | string
    duration_minutes: number
    scheduled_start_date_date: Date
    scheduled_start_date: Date
    scheduled_end_date: Date
    actual_start_date: Date
    actual_end_date: Date
    scheduled_duration_hours_minutes: string
    is_flexible_on_day: boolean
    is_flexible_on_time: boolean

    unfilled_hours_in_next_two_months: number
    last_confirmed_appointment_scheduled_start_date: Date

    is_override_amount_to_client: boolean
    is_override_amount_to_supplier: boolean

    supplier_kilometers_travelled: number

    supplier_base_cost_ex_gst: number
    supplier_base_cost_gst: number
    supplier_base_cost_inc_gst: number

    supplier_travel_cost_ex_gst: number
    supplier_travel_cost_gst: number
    supplier_travel_cost_inc_gst: number

    supplier_call_out_fee_ex_gst: number
    supplier_call_out_fee_gst: number
    supplier_call_out_fee_inc_gst: number

    supplier_min_time_charge_ex_gst: number
    supplier_min_time_charge_gst: number
    supplier_min_time_charge_inc_gst: number

    supplier_admin_fee_ex_gst: number
    supplier_admin_fee_gst: number
    supplier_admin_fee_inc_gst: number

    supplier_cost_ex_gst: number
    supplier_cost_gst: number
    supplier_cost_inc_gst: number

    margin: number

    client_cost_ex_gst: number
    client_cost_gst: number
    client_cost_inc_gst: number
    supplier_rebate_ex_gst: number
    supplier_rebate_gst: number

    count_past_appointments_to_cancel: number
    count_client_notification_appointments: number

    notes: string
    potential_attendance_locations: PotentialAttendanceLocationInterface[]

    lu_supplier_client_notification_type_id: number
    supplier_client_notification_details: string
    override_engaged_supplier_description: string

    count_open_supplier_booking_requests: number
    is_new_recurrence_rule_for_supplier: boolean

    provider_description: string
    provider_id: number
}

export interface AppointmentsBulkUpdateInterface extends AuditEntityInterface {
    id: number
    lu_appointments_bulk_update_type_id: number
}

export type AppointmentRescheduleType = 'Replaced by Supplier' | 'Replaced by Provider'

export interface AppointmentRescheduleInterface extends AuditEntityInterface {
    id: number

    appointment_id: number
    appointment: AppointmentInterface

    appointments_bulk_update_id: number
    lu_appointment_reschedule_type_id: number
    lu_appointment_status_id: number

    previous_supplier_id: number
    new_supplier_id: number

    previous_worker_id: number
    new_worker_id: number

    previous_lu_appointment_attendance_location_id: number
    new_lu_appointment_attendance_location_id: number

    other_client_cancellation_reason: string
    other_provider_cancellation_reason: string
    other_worker_cancellation_reason: string

    lu_appointment_cancellation_source_id: number
    lu_appointment_cancellation_client_reason_id: number
    lu_appointment_cancellation_provider_reason_id: number
    lu_appointment_cancellation_worker_reason_id: number

    appointment_reschedule_type_description: AppointmentRescheduleType
    status: string

    previous_supplier_description: string
    new_supplier_description: string

    previous_worker_description: string
    new_worker_description: string

    previous_scheduled_start_date: Date
    new_scheduled_start_date: Date

    previous_scheduled_end_date: Date
    new_scheduled_end_date: Date

    previous_supplier_cost_inc_gst: string
    new_supplier_cost_inc_gst: string

    previous_client_cost_inc_gst: string
    new_client_cost_inc_gst: string

    previous_lu_appointment_attendance_location_description: string
    new_lu_appointment_attendance_location_description: string

    appointment_cancellation_source_description: string
    appointment_cancellation_client_reason_description: string
    appointment_cancellation_provider_reason_description: string
    appointment_cancellation_worker_reason_description: string
    full_appointment_cancellation_description: string

    provider_description: string
    provider_short_description: string
}

export interface RescheduleChangesInterface {
    previous_start_time: Date
    new_start_time: Date
    previous_start_day: number
    new_start_day: number

    previous_end_time: Date
    new_end_time: Date
    previous_end_day: number
    new_end_day: number

    previous_worker_id: number
    new_worker_id: number
    previous_worker_description: string
    new_worker_description: string
    previous_supplier_id: number
    new_supplier_id: number
    previous_supplier_description: string
    new_supplier_description: string

    application_user_id: number
    application_user_full_name: string
    application_user_description: string
    created_on: Date
    appointment_reschedule_id: number
    has_changes: boolean
    new_start_day_description: string
    previous_start_day_description: string
    new_end_day_description: string
    previous_end_day_description: string
}

export interface SupplierBookingGroupInterface {
    [key: string]: any

    id: number

    appointments: AppointmentInterface[]

    is_recurrence_rule: boolean
    is_ad_hoc: boolean

    appointment_id: number
    recurrence_rule_id: number
    first_appointment_date: Date
    last_appointment_date: Date
    first_series_appointment_date: Date
    duration: string

    client_id: string
    client_description: string
    type_description: string
    frequency_description: string
    suburb_description: string
    service_description: string

    engaged_supplier_description: string
    engaged_supplier_id: number

    service_id: number
    postcode: string
}

export interface SupplierBookedAppointmentsReportInterface {
    supplier_id: number
    supplier_booking_groups: SupplierBookingGroupInterface[]
    appointments: AppointmentInterface[]
    from_date: Date
    to_date: Date
}

export interface BillingCycleDataInterface {
    id: number
    from_date: Date
    to_date: Date
}

export interface SupplierConfirmedAppointmentsReportInterface {
    supplier_id: number
    supplier_booking_groups: SupplierBookingGroupInterface[]
    appointments: AppointmentInterface[]
    from_date: Date
    to_date: Date
    billing_cycle_data: BillingCycleDataInterface[]
}

export interface WeekDayOptionInterface {
    day_of_week: number
    description: string
}

export interface AppointmentRescheduleControllerInterface {
    appointments: AppointmentEditInterface[]
    recurrence_rule: RecurrenceRuleInterface
    start_time: string
    end_time: string

    start_datetime: Date
    end_datetime: Date

    is_one_day_per_week_every_week: boolean
    by_week_day: number
    day_offset: number

    by_week_day_options: WeekDayOptionInterface[]
}

export interface AppointmentsReallocateWorkerControllerInterface {
    appointments: AppointmentInterface[]
    updated_appointments: AppointmentInterface[]
}

export interface MeetingAppointmentCancellationControllerInterface {
    meeting_appointment: MeetingAppointmentInterface
    possible_meeting_appointment_cancellation_reasons: LookupInterface[]
    lu_meeting_appointment_cancellation_reason_id: number
    other_cancellation_reason: string
}

export const enum MeetingAppointmentMeetingAppointmentTypeIdEnum {
    LU_MEETING_APPOINTMENT_TYPE_ACAT = 1,
    LU_MEETING_APPOINTMENT_TYPE_PHONE_SCREENING = 2,
    LU_MEETING_APPOINTMENT_TYPE_IHA_SAFETY = 3,
    LU_MEETING_APPOINTMENT_TYPE_IHA_PERSONAL = 4,
    LU_MEETING_APPOINTMENT_TYPE_IHA_FRAT = 5,
    LU_MEETING_APPOINTMENT_TYPE_IHA_CARE_PLANNING = 6,
    LU_MEETING_APPOINTMENT_TYPE_INITIAL_CARE_MANAGER_ASSESSMENT = 7,
}

export const enum MeetingAppointmentCancellationReasonIdEnum {
    LU_MEET_APPT_CANCELLATION_REASON_CARE_MANAGER_RESCHEDULE = 1,
    LU_MEET_APPT_CANCELLATION_REASON_CLIENT_RESCHEDULE = 2,
    LU_MEET_APPT_CANCELLATION_REASON_CLIENT_NOT_PROCEEDING = 3,
    LU_MEET_APPT_CANCELLATION_REASON_NEW_CARE_MANAGER = 4,
    LU_MEET_APPT_CANCELLATION_REASON_CLIENT_OFFBOARDING = 5,
    LU_MEET_APPT_CANCELLATION_REASON_CLIENT_ON_LEAVE = 6,
    LU_MEET_APPT_CANCELLATION_REASON_CLIENT_UNWELL = 7,
    LU_MEET_APPT_CANCELLATION_REASON_CLIENT_OTHER = 8,
    LU_MEET_APPT_CANCELLATION_REASON_CARE_MANAGER_ON_LEAVE = 9,
    LU_MEET_APPT_CANCELLATION_REASON_CARE_MANAGER_MISSED_MEETING = 10,
}

export interface OperationInterface {
    appointment?: AppointmentEditInterface
    new_appointment?: AppointmentEditInterface
    has_applied: boolean
    notes: string[]

    is_cancellation_operation: boolean
    is_creation_operation: boolean
    is_reschedule_operation: boolean
    is_static_change_operation: boolean
    is_no_change_operation: boolean
    is_invalid_operation_reasons: string[]
    is_invalid_operation: boolean
    description: string
    type_description: string
    is_reschedule_target?: boolean
}

export interface AppointmentAdjustmentsLogReportInterface {
    appointments: AppointmentInterface[]
    supplier_data: SupplierAppointmentAdjustmentsLogReportInterface[]
    service_data: ServiceAppointmentAdjustmentsLogReportInterface[]

    service_descriptions: string[]
    supplier_descriptions: string[]

    total_appointments: number
    total_appointments_booked_within_48_hours: number
    total_appointments_booked_within_10_days: number
    total_ad_hoc_appointments: number
    total_appointments_booked_retrospectively: number
    percentage_appointments_booked_within_48_hours: number
    percentage_appointments_booked_within_10_days: number
    percentage_ad_hoc_appointments: number
    percentage_appointments_booked_retrospectively: number
    total_supplier_cost_inc_gst: number
    total_client_cost_inc_gst: number

    total_original_to_booked_duration_delta: number
    total_original_to_booked_start_date_delta: number
    total_original_to_booked_end_date_delta: number
    total_booked_to_final_duration_delta: number
    total_booked_to_final_start_date_delta: number
    total_booked_to_final_end_date_delta: number

    percentage_original_to_booked_duration_delta: number
    percentage_original_to_booked_start_date_delta: number
    percentage_original_to_booked_end_date_delta: number
    percentage_booked_to_final_duration_delta: number
    percentage_booked_to_final_start_date_delta: number
    percentage_booked_to_final_end_date_delta: number

    total_with_surcharge_cost_adjustment: number
    total_with_supplier_appointment_cost_adjustment: number
    total_with_client_cost_adjustment: number

    percentage_with_surcharge_cost_adjustment: number
    percentage_with_supplier_appointment_cost_adjustment: number
    percentage_with_client_cost_adjustment: number

    total_has_original_to_booked_date_adjustment_only: number
    total_has_updated_original_to_booked_duration_and_date: number
    total_has_booked_to_final_date_adjustment_only: number
    total_has_updated_booked_to_final_duration_and_date: number
    total_is_booked_after_original_scheduled_start_date: number

    percentage_has_booked_to_final_date_adjustment_only: number
    percentage_has_original_to_booked_date_adjustment_only: number
    percentage_has_updated_booked_to_final_duration_and_date: number
    percentage_has_updated_original_to_booked_duration_and_date: number
    percentage_is_booked_after_original_scheduled_start_date: number
}

export interface SupplierAppointmentAdjustmentsLogReportInterface extends AppointmentAdjustmentsLogReportInterface {
    id: number
    supplier_description: string
    has_portal_access: boolean
}

export interface ServiceAppointmentAdjustmentsLogReportInterface extends AppointmentAdjustmentsLogReportInterface {
    id: number
    service_description: string
}

export interface AppointmentStatusReportAppointmentDataManyInterface {
    id: number
    status: string
    appointment_id: number
    client_id: number
    lu_appointment_status_id: number
    client_description: string
    supplier_description: string
    service_description: string
    scheduled_start_date: Date
    duration_minutes: number
    service_coordinator_application_user_description: string
    is_cancelled: boolean
    cancelled_on: Date
    cancelled_on_days_before: number
    cancelled_by_application_user_description: string
    supplier_cost_inc_gst: number
    client_cost_inc_gst: number
    is_client_charged: boolean
    is_client_incurred_fees: boolean
    is_provider_cost: boolean
    is_provider_error: boolean
    is_disputed: boolean
    is_client_cancelled: boolean
    is_unfilled: boolean
    is_missed: boolean
    appointment_cancellation_source_description: string
    appointment_cancellation_reason_description: string
    appointment_cancellation_details: string
    booked_status: number
    booked_status_description: string
    planned_status: number
    planned_status_description: string
    attended_status: number
    attended_status_description: string
    pre_cancellation_status: string
}

export interface AppointmentStatusReportInterface {
    from_date: Date
    to_date: Date
    appointment_data: AppointmentStatusReportAppointmentDataManyInterface[]
    count_all_appointments: number
    count_appointments: number
    count_cancelled_appointments: number
    percentage_cancelled_appointments: number
    count_disputed_appointments: number
    percentage_disputed_appointments: number
    count_incurs_fee_appointments: number
    percentage_incurs_fee_appointments: number
    count_provider_cost_appointments: number
    percentage_provider_cost_appointments: number
    count_client_cancellation_appointments: number
    percentage_client_cancelled_appointments: number
    count_missed_appointments: number
    percentage_missed_appointments: number
    count_unfilled_appointments: number
    percentage_unfilled_appointments: number
    total_provider_cost_supplier_payable_inc_gst: number
    percentage_fill_rate: number
    percentage_fill_rate_on_time: number
    percentage_coordinator_performance: number
    percentage_coordinator_performance_late_notice_ignored: number
    percentage_sent: number
    percentage_reliability: number
}
