import {FC} from 'react'
import {Grid2 as Grid} from '@mui/material'
import {GenevaUserInterface} from '@/interfaces/user_types'
import WorkerUpcomingAppointmentsCard from '@/components/worker/WorkerUpcomingAppointmentsCard'
import useGenevaFetch from '@/hooks/useGenevaFetch'
import ServerError from '@/views/error/ServerError'
import ProviderFeedbackIncidentsCard from '@/components/provider/ProviderFeedbacksIncidentsCard'
import {CalendarEventInterface} from '@/interfaces/appointment_types'
import UserSupplierLeadsCard from '@/components/user/UserSupplierLeadsCard'
import UserAgenda from '@/components/user/UserAgenda'
import UserTasksCreatedByMeCard from '@/components/user/UserTasksCreatedByMeCard'
import ProviderLoggedLeaveCard from '@/components/provider/ProviderLoggedLeaveCard'

interface Props {
    application_user: GenevaUserInterface
}

interface WorkerUpcomingAppointmentsProps {
    person_id: number
}

const WorkerUpcomingAppointments: FC<WorkerUpcomingAppointmentsProps> = ({person_id}) => {
    const {data, error, isLoading} = useGenevaFetch(`/api/persons/${person_id}/schedule`)

    if (error) {
        return <ServerError />
    }

    const upcomingAppointments =
        !isLoading && data ? (data.schedule.upcoming_appointment_events as CalendarEventInterface[]) : []

    return isLoading || upcomingAppointments.length > 0 ? (
        <Grid size={6}>
            <WorkerUpcomingAppointmentsCard isLoading={isLoading} appointments={upcomingAppointments} />
        </Grid>
    ) : null
}

const Overview: FC<Props> = ({application_user}) => {
    return (
        <Grid container spacing={3}>
            <Grid size={12}>
                <UserAgenda genevaUser={application_user} boldTile={true} allowIncludeReports={true} />
            </Grid>
            <UserTasksCreatedByMeCard application_user={application_user} />
            {(application_user.is_admin ||
                application_user.is_manager ||
                application_user.is_operations_manager_care_management) && (
                <Grid size={6}>
                    <ProviderFeedbackIncidentsCard />
                </Grid>
            )}
            <WorkerUpcomingAppointments person_id={application_user.person_id} />
            <Grid size={6}>
                <ProviderLoggedLeaveCard />
            </Grid>
            <UserSupplierLeadsCard application_user={application_user} />
        </Grid>
    )
}

export default Overview
