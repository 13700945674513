import {FC} from 'react'
import {Alert, AlertTitle, Box, Button} from '@mui/material'
import useGenevaFetch from '@/hooks/useGenevaFetch'
import ServerError from '@/views/error/ServerError'
import {ProviderFeedbacksIncidentsInterface} from '@/interfaces/provider_types'
import {useRouter} from 'next/router'

interface Props {}

const UserIncidentAlert: FC<Props> = () => {
    const router = useRouter()
    const {data, isLoading, error} = useGenevaFetch(`/api/reports/feedbacks_incidents`)

    if (error) {
        console.error(error)
        return <ServerError />
    }

    const feedbacks_incidents =
        !isLoading && data ? (data.feedbacks_incidents as ProviderFeedbacksIncidentsInterface) : null

    const handleClick = () => {
        router.push('/provider/incidents')
    }

    return feedbacks_incidents && feedbacks_incidents.count_of_unreported_sirs_incidents > 0 ? (
        <Box sx={{mb: 2}}>
            <Alert
                severity="error"
                action={
                    <Button color="inherit" size="small" onClick={handleClick}>
                        VIEW
                    </Button>
                }
            >
                <AlertTitle>SIRS Incident Manager Warning</AlertTitle>
                Unreported SIRS report, please review and complete entry
            </Alert>
        </Box>
    ) : null
}

export default UserIncidentAlert
