import useGenevaUser from '@/hooks/useGenevaUser'
import LoadingScreen from '@/components/widgets/LoadingScreen'
import UserHomePage from '@/views/user/UserHomePage'
import HomePageDashboard from '@/pages/dashboard'

const IndexPage = () => {
    const {genevaUser, isGenevaLoading} = useGenevaUser()

    return (
        <HomePageDashboard>
            {!isGenevaLoading && genevaUser ? <UserHomePage application_user={genevaUser} /> : <LoadingScreen />}
        </HomePageDashboard>
    )
}

export default IndexPage
