import {FC} from 'react'
import {CalendarEventInterface} from '@/interfaces/appointment_types'
import WorkerUpcomingAppointmentsList from './WorkerUpcomingAppointmentsList'

interface Props {
    isLoading: boolean
    appointments: CalendarEventInterface[]
}

const WorkerUpcomingAppointmentsCard: FC<Props> = ({isLoading, appointments}) => {
    return <WorkerUpcomingAppointmentsList isLoading={isLoading} appointments={appointments} />
}

export default WorkerUpcomingAppointmentsCard
